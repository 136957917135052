/* make the customizations */
// $theme-colors: (
//   'info': tomato,
//   'danger': teal,
// );

$enable-negative-margins: true;

@import '~bootstrap/scss/bootstrap';

.monospace-text {
  font-family: 'Roboto Mono', monospace;
}
